import { graphql, useStaticQuery } from 'gatsby';
import { AllDirectusPageNotFound, PageNotFoundEdges } from './page-not-found.interface';

export default (): PageNotFoundEdges[] => {
  const { allDirectusPageNotFound } = useStaticQuery<AllDirectusPageNotFound>(graphql`
    query {
      allDirectusPageNotFound {
        edges {
          node {
            title
            sub_title
            image {
              data {
                full_url
              }
              width
              height
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            image_alt
            body
            link
            link_text
          }
        }
      }
    }
  `);

  return allDirectusPageNotFound.edges;
};

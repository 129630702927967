import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';

import Section from 'components/common/section/Section';
import { Wrapper } from 'components/common/wrapper/Wrapper';
import PageNotFound from 'components/directus/page-not-found/PageNotFound';
import { DirectusPageData } from 'interfaces/directus-page-data.interface';
import { getFieldBySlug } from 'utils/directus/get-field-by-slug/get-field-by-slug';
import { PageProps } from 'utils/gatsby.interface';

import { background, wrapper } from 'pages-styles/404.scss';

export default ({ data }: PageProps<DirectusPageData>): JSX.Element => {
  const { node } = getFieldBySlug('404-error', data.allDirectusPage.edges);

  return (
    <>
      <Helmet>
        <title>{node.title}</title>
        <meta name="title" content={node.title} />
        <meta name="description" content={node.description} />
        <meta name="robots" content={node.robots} />
      </Helmet>

      <div className={background}>
        <Wrapper className={wrapper}>
          <Section bottom={0}>
            <PageNotFound />
          </Section>
        </Wrapper>
      </div>
    </>
  );
};

export const query = graphql`
  query {
    allDirectusPage(filter: { slug: { eq: "404-error" } }) {
      edges {
        node {
          title
          slug
          description
          robots
        }
      }
    }
  }
`;

import React from 'react';
import Img from 'gatsby-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleLeft } from '@fortawesome/pro-solid-svg-icons/faChevronCircleLeft';

import { Link } from 'components/common/link/Link';
import Markdown from 'components/common/markdown/Markdown';
import pageNotFoundHook from 'hooks/page-not-found/page-not-found.hook';

import { content, title as titleClass, subTitle, link as linkClass, link__icon, img } from './PageNotFound.scss';

export default (): JSX.Element => {
  const pageNotFoundData = () => {
    const data = pageNotFoundHook();

    if (data.length === 0) {
      throw new Error('pageNotFound directus collection does not have any fields.');
    }

    return data[0].node;
  };

  const { title, sub_title, image, image_alt, body, link, link_text } = pageNotFoundData();

  return (
    <div className={content}>
      <div>
        <h1 className={titleClass}>{title}</h1>
        <h3 className={subTitle}>{sub_title}</h3>
        <Markdown source={body} container />

        <Link to={link} className={linkClass}>
          <FontAwesomeIcon icon={faChevronCircleLeft} className={link__icon} />
          {link_text}
        </Link>
      </div>

      <div>
        <Img fluid={image.localFile.childImageSharp.fluid} className={img} alt={image_alt} fadeIn />
      </div>
    </div>
  );
};
